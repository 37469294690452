<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900">
        Register A Shop
      </a>

      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Create and account
          </h1>
          <form class="space-y-4 md:space-y-6" @submit.prevent="handleSubmit">
            <div class="flex gap-2 justify-between">
              <div>
                <label for="outletname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Outlet Name</label>
                <input  required v-model="outletname" type="text" name="outletname" id="outletname" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Vinny Enterprices" >
              </div>
              <div>
                <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Owner's Phone</label>
                <input  required v-model="phone" type="phone" name="phone" id="phone" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0720000000" >
              </div>
            </div>
            <div class="flex gap-2">
              <div>
                <label for="fname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Owner's First Name</label>
                <input  required v-model="firstname" type="text" name="fname" id="fname" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" >
              </div>
              <div>
                <label for="lname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Owner's Last Name</label>
                <input  required v-model="lastname" type="text" name="lname" id="lname" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Doe" >
              </div>
           </div>

            <div class="flex gap-2">
<!--              <div>-->
<!--                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>-->
<!--                <input v-model="email" type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@doe.com" >-->
<!--              </div>-->
              <div style="width: 100%;">
                <label for="zone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Zone</label>
                <!--                <input  required v-model="zone" type="zone" name="zone" id="zone" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Zone" >-->

                <select id="zone" v-model="zone" @change="loadShops"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option style="width: 100%;" value="">Select Zone</option>
                  <option
                      v-for="zone in zones"
                      :key="zone.id"
                      :value="zone.delivery_zone"
                  >
                    {{ zone.delivery_zone }}
                  </option>
                </select>
              </div>
              <div style="width: 100%;">
                <label for="shopname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">M-Mauzo Agent</label>
                <!--                <input  required v-model="shopname" type="shopname" name="zone" id="shopname" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Shopname" >-->
                <select id="shopname" v-model="shopname"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="">Select Agent</option>
                  <option
                      v-for="shop in shops"
                      :key="shop.store_id"
                      :value="shop.store_id"
                  >

                    {{ shop.store_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="flex gap-2 justify-between">
              <div style="width: 100%;">
                <label for="county" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">County</label>
                <!--                <input  required v-model="shopname" type="shopname" name="zone" id="shopname" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Shopname" >-->
                <select id="county" v-model="county" @change="loadConstituencies"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="">Select County</option>
                  <option
                      v-for="county in counties"
                      :key="county.name"
                      :value="county.id"
                  >

                    {{ county.name }}
                  </option>
                </select>
              </div>
              <div style="width: 100%;">
                <label for="zone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Constituency</label>
<!--                <input  required v-model="zone" type="zone" name="zone" id="zone" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Zone" >-->

                <select id="constituency" v-model="constituency" @change="loadWards()"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option style="width: 100%;" value="">Select Constituency</option>
                  <option
                      v-for="constituency in constituencies"
                      :key="constituency.id"
                      :value="constituency.id"
                  >
                    {{ constituency.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="flex gap-2">
              <div style="width: 100%;">
                <label for="ward" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ward</label>
                <!--                <input  required v-model="zone" type="zone" name="zone" id="zone" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Zone" >-->

                <select id="ward" v-model="ward"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option style="width: 100%;" value="">Select Ward</option>
                  <option
                      v-for="ward in wards"
                      :key="ward.id"
                      :value="ward.id"
                  >
                    {{ ward.name }}
                  </option>
                </select>
              </div>
            <div style="width: 100%;">
              <label for="estate" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Estate</label>
              <input  required v-model="estate" type="estate" name="zone" id="estate" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Estate" >
            </div>

            </div>

            <input  required v-model="zip" type="hidden" name="zone" id="zip" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Zip" >

            <div class="flex gap-2 justify-between">
              <div style="width: 100%;">
                <label for="street" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Street</label>
                <input  required v-model="street" type="street" name="zone" id="street" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Street" >
              </div>
            <div style="width: 100%;">
              <label for="landmark" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Landmark</label>
              <input  required v-model="landmark" type="text" name="landmark" id="landmark" placeholder="Nearest landmark" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" >
            </div>
            </div>
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
              <input  required v-model="pw" type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" >
            </div>
            <div>
              <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
              <input  required v-model="rpw" type="confirm-password" name="confirm-password" id="confirm-password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" >
            </div>
<!--            <div class="flex items-start">-->
<!--              <div class="flex items-center h-5">-->
<!--                <input id="terms" aria-describedby="terms" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" >-->
<!--              </div>-->
<!--              <div class="ml-3 text-sm">-->
<!--                <label for="terms" class="font-light text-gray-500 dark:text-gray-300">I accept the <a class="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">Terms and Conditions</a></label>-->
<!--              </div>-->
<!--            </div>-->
            <button
                :disabled="isLoading"
                type="submit" style="background-color: #03a9f4;" class="w-full text-white bg-[#03a9f4] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
              <spinner class="spinner-sm" v-if="isLoading" />
              <span v-else>
                Register Shop
              </span>
            </button>
<!--            <p class="text-sm font-light text-gray-500 dark:text-gray-400">-->
<!--              Already have an account? <a href="#" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</a>-->
<!--            </p>-->
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { onMounted,defineComponent, ref, reactive, computed, watch, toRefs } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import router from "@/router";

export default defineComponent({

  setup() {
    const store = useStore();

    const formData = reactive({
      firstname: "",
      lastname: "",
      phone: "",
      zone: "",
      shopname: "",
      constituency:"",
      landmark:"",
      outletname:"",
      county:"",
      estate: "",
      street: "",
      ward: "",
      zip: "",
      email: "",
      pw: "",
      rpw: "",
      errors: {
        firstname: false,
        lastname: false,
        phone: false,
        landmark:false,
        zone: false,
        shopname: false,
        outletname: false,
        county: false,
        estate: false,
        street: false,
        ward: false,
        constituency: false,
        zip: false,
        email: false,
        pw: false,
      },
      counties_:[
        {
          "name": "Baringo",
          "capital": "Kabarnet",
          "code": 30,
          "sub_counties": [
            "Baringo central",
            "Baringo north",
            "Baringo south",
            "Eldama ravine",
            "Mogotio",
            "Tiaty"
          ]
        },
        {
          "name": "Bomet",
          "capital": "Bomet",
          "code": 36,
          "sub_counties": [
            "Bomet central",
            "Bomet east",
            "Chepalungu",
            "Konoin",
            "Sotik"
          ]
        },
        {
          "name": "Bungoma",
          "capital": "Bungoma",
          "code": 39,
          "sub_counties": [
            "Bumula",
            "Kabuchai",
            "Kanduyi",
            "Kimilil",
            "Mt Elgon",
            "Sirisia",
            "Tongaren",
            "Webuye east",
            "Webuye west"
          ]
        },
        {
          "name": "Busia",
          "capital": "Busia",
          "code": 40,
          "sub_counties": [
            "Budalangi",
            "Butula",
            "Funyula",
            "Nambele",
            "Teso North",
            "Teso South"
          ]
        },
        {
          "name": "Elgeyo-Marakwet",
          "capital": "Iten",
          "code": 28,
          "sub_counties": [
            "Keiyo north",
            "Keiyo south",
            "Marakwet east",
            "Marakwet west"
          ]
        },
        {
          "name": "Embu",
          "capital": "Embu",
          "code": 14,
          "sub_counties": [
            "Manyatta",
            "Mbeere north",
            "Mbeere south",
            "Runyenjes"
          ]
        },
        {
          "name": "Garissa",
          "capital": "Garissa",
          "code": 7,
          "sub_counties": [
            "Daadab",
            "Fafi",
            "Garissa",
            "Hulugho",
            "Ijara",
            "Lagdera balambala"
          ]
        },
        {
          "name": "Homa Bay",
          "capital": "Homa Bay",
          "code": 43,
          "sub_counties": [
            "Homabay town",
            "Kabondo",
            "Karachwonyo",
            "Kasipul",
            "Mbita",
            "Ndhiwa",
            "Rangwe",
            "Suba"
          ]
        },
        {
          "name": "Isiolo",
          "capital": "Isiolo",
          "code": 11,
          "sub_counties": [
            "Central",
            "Garba tula",
            "Kina",
            "Merit",
            "Oldonyiro",
            "Sericho"
          ]
        },
        {
          "name": "Kajiado",
          "code": 34,
          "sub_counties": [
            "Isinya.",
            "Kajiado Central.",
            "Kajiado North.",
            "Loitokitok.",
            "Mashuuru."
          ]
        },
        {
          "name": "Kakamega",
          "capital": "Kakamega",
          "code": 37,
          "sub_counties": [
            "Butere",
            "Kakamega central",
            "Kakamega east",
            "Kakamega north",
            "Kakamega south",
            "Khwisero",
            "Lugari",
            "Lukuyani",
            "Lurambi",
            "Matete",
            "Mumias",
            "Mutungu",
            "Navakholo"
          ]
        },
        {
          "name": "Kericho",
          "capital": "Kericho",
          "code": 35,
          "sub_counties": [
            "Ainamoi",
            "Belgut",
            "Bureti",
            "Kipkelion east",
            "Kipkelion west",
            "Soin sigowet"
          ]
        },
        {
          "name": "Kiambu",
          "capital": "Kiambu",
          "code": 22,
          "sub_counties": [
            "Gatundu north",
            "Gatundu south",
            "Githunguri",
            "Juja",
            "Kabete",
            "Kiambaa",
            "Kiambu",
            "Kikuyu",
            "Limuru",
            "Ruiru",
            "Thika town",
            "lari"
          ]
        },
        {
          "name": "Kilifi",
          "capital": "Kilifi",
          "code": 3,
          "sub_counties": [
            "Genzw",
            "Kaloleni",
            "Kilifi north",
            "Kilifi south",
            "Magarini",
            "Malindi",
            "Rabai"
          ]
        },
        {
          "name": "Kirinyaga",
          "capital": "Kerugoya/Kutus",
          "code": 20,
          "sub_counties": [
            "Kirinyaga central",
            "Kirinyaga east",
            "Kirinyaga west",
            "Mwea east",
            "Mwea west"
          ]
        },
        {
          "name": "Kisii",
          "capital": "Kisii",
          "code": 45,
          "sub_counties": [

          ]
        },
        {
          "name": "Kisumu",
          "capital": "Kisumu",
          "code": 42,
          "sub_counties": [
            "Kisumu central",
            "Kisumu east ",
            "Kisumu west",
            "Mohoroni",
            "Nyakach",
            "Nyando",
            "Seme"
          ]
        },
        {
          "name": "Kitui",
          "capital": "Kitui",
          "code": 15,
          "sub_counties": [
            "Ikutha",
            "Katulani",
            "Kisasi",
            "Kitui central",
            "Kitui west ",
            "Lower yatta",
            "Matiyani",
            "Migwani",
            "Mutitu",
            "Mutomo",
            "Muumonikyusu",
            "Mwingi central",
            "Mwingi east",
            "Nzambani",
            "Tseikuru"
          ]
        },
        {
          "name": "Kwale",
          "capital": "Kwale",
          "code": 2,
          "sub_counties": [
            "Kinango",
            "Lungalunga",
            "Msambweni",
            "Mutuga"
          ]
        },
        {
          "name": "Laikipia",
          "capital": "Rumuruti",
          "code": 31,
          "sub_counties": [
            "Laikipia central",
            "Laikipia east",
            "Laikipia north",
            "Laikipia west ",
            "Nyahururu"
          ]
        },
        {
          "name": "Lamu",
          "capital": "Lamu",
          "code": 5,
          "sub_counties": [
            "Lamu East",
            "Lamu West"
          ]
        },
        {
          "name": "Machakos",
          "capital": "Machakos",
          "code": 16,
          "sub_counties": [
            "Kathiani",
            "Machakos town",
            "Masinga",
            "Matungulu",
            "Mavoko",
            "Mwala",
            "Yatta"
          ]
        },
        {
          "name": "Makueni",
          "capital": "Wote",
          "code": 17,
          "sub_counties": [
            "Kaiti",
            "Kibwei west",
            "Kibwezi east",
            "Kilome",
            "Makueni",
            "Mbooni"
          ]
        },
        {
          "name": "Mandera",
          "capital": "Mandera",
          "code": 9,
          "sub_counties": [
            "Banissa",
            "Lafey",
            "Mandera East",
            "Mandera North",
            "Mandera South",
            "Mandera West"
          ]
        },
        {
          "name": "Marsabit",
          "capital": "Marsabit",
          "code": 10,
          "sub_counties": [
            "Laisamis",
            "Moyale",
            "North hor",
            "Saku"
          ]
        },
        {
          "name": "Meru",
          "capital": "Meru",
          "code": 12,
          "sub_counties": [
            "Buuri",
            "Igembe central",
            "Igembe north",
            "Igembe south",
            "Imenti central",
            "Imenti north",
            "Imenti south",
            "Tigania east",
            "Tigania west"
          ]
        },
        {
          "name": "Migori",
          "capital": "Migori",
          "code": 44,
          "sub_counties": [
            "Awendo",
            "Kuria east",
            "Kuria west",
            "Mabera",
            "Ntimaru",
            "Rongo",
            "Suna east",
            "Suna west",
            "Uriri"
          ]
        },
        {
          "name": "Mombasa",
          "capital": "Mombasa City",
          "code": 1,
          "sub_counties": [
            "Changamwe",
            "Jomvu",
            "Kisauni",
            "Likoni",
            "Mvita",
            "Nyali"
          ]
        },
        {
          "name": "Murang'a",
          "capital": "Murang'a",
          "code": 21,
          "sub_counties": [
            "Gatanga",
            "Kahuro",
            "Kandara",
            "Kangema",
            "Kigumo",
            "Kiharu",
            "Mathioya",
            "Murang’a south"
          ]
        },
        {
          "name": "Nairobi",
          "capital": "Nairobi City",
          "code": 47,
          "sub_counties": [
            "Dagoretti North Sub County",
            "Dagoretti South Sub County ",
            "Embakasi Central Sub Count",
            "Embakasi East Sub County",
            "Embakasi North Sub County ",
            "Embakasi South Sub County",
            "Embakasi West Sub County",
            "Kamukunji Sub County",
            "Kasarani Sub County ",
            "Kibra Sub County ",
            "Lang'ata Sub County ",
            "Makadara Sub County",
            "Mathare Sub County ",
            "Roysambu Sub County ",
            "Ruaraka Sub County ",
            "Starehe Sub County ",
            "Westlands Sub County "
          ]
        },
        {
          "name": "Nakuru",
          "capital": "Nakuru",
          "code": 32,
          "sub_counties": [
            "Bahati",
            "Gilgil",
            "Kuresoi north",
            "Kuresoi south",
            "Molo",
            "Naivasha",
            "Nakuru town east",
            "Nakuru town west",
            "Njoro",
            "Rongai",
            "Subukia"
          ]
        },
        {
          "name": "Nandi",
          "capital": "Kapsabet",
          "code": 29,
          "sub_counties": [
            "Aldai",
            "Chesumei",
            "Emgwen",
            "Mosop",
            "Namdi hills",
            "Tindiret"
          ]
        },
        {
          "name": "Narok",
          "capital": "Narok",
          "code": 33,
          "sub_counties": [
            "Narok east",
            "Narok north",
            "Narok south",
            "Narok west",
            "Transmara east",
            "Transmara west"
          ]
        },
        {
          "name": "Nyamira",
          "capital": "Nyamira",
          "code": 46,
          "sub_counties": [
            "Borabu",
            "Manga",
            "Masaba north",
            "Nyamira north",
            "Nyamira south"
          ]
        },
        {
          "name": "Nyandarua",
          "capital": "Ol Kalou",
          "code": 18,
          "sub_counties": [
            "Kinangop",
            "Kipipiri",
            "Ndaragwa",
            "Ol Kalou",
            "Ol joro orok"
          ]
        },
        {
          "name": "Nyeri",
          "capital": "Nyeri",
          "code": 19,
          "sub_counties": [
            "Kieni east",
            "Kieni west",
            "Mathira east",
            "Mathira west",
            "Mkurweni",
            "Nyeri town",
            "Othaya",
            "Tetu"
          ]
        },
        {
          "name": "Samburu",
          "capital": "Maralal",
          "code": 25,
          "sub_counties": [
            "Samburu east",
            "Samburu north",
            "Samburu west"
          ]
        },
        {
          "name": "Siaya",
          "capital": "Siaya",
          "code": 41,
          "sub_counties": [
            "Alego usonga",
            "Bondo",
            "Gem",
            "Rarieda",
            "Ugenya",
            "Unguja"
          ]
        },
        {
          "name": "Taita-Taveta",
          "capital": "Voi",
          "code": 6,
          "sub_counties": [
            "Mwatate",
            "Taveta",
            "Voi",
            "Wundanyi"
          ]
        },
        {
          "name": "Tana River",
          "capital": "Hola",
          "code": 4,
          "sub_counties": [
            "Bura",
            "Galole",
            "Garsen"
          ]
        },
        {
          "name": "Tharaka-Nithi",
          "capital": "Chuka",
          "code": 13,
          "sub_counties": [
            "Chuka",
            "Igambangobe",
            "Maara",
            "Muthambi",
            "Tharak north",
            "Tharaka south"
          ]
        },
        {
          "name": "Trans-Nzoia",
          "capital": "Kitale",
          "code": 26,
          "sub_counties": [
            "Cherangany",
            "Endebess",
            "Kiminini",
            "Kwanza",
            "Saboti"
          ]
        },
        {
          "name": "Turkana",
          "capital": "Lodwar",
          "code": 23,
          "sub_counties": [
            "Loima",
            "Turkana central",
            "Turkana east",
            "Turkana north",
            "Turkana south"
          ]
        },
        {
          "name": "Uasin Gishu",
          "capital": "Eldoret",
          "code": 27,
          "sub_counties": [
            "Ainabkoi",
            "Kapseret",
            "Kesses",
            "Moiben",
            "Soy",
            "Turbo"
          ]
        },
        {
          "name": "Vihiga",
          "capital": "Vihiga",
          "code": 38,
          "sub_counties": [
            "Emuhaya",
            "Hamisi",
            "Luanda",
            "Sabatia",
            "vihiga"
          ]
        },
        {
          "name": "Wajir",
          "capital": "Wajir",
          "code": 8,
          "sub_counties": [
            "Eldas",
            "Tarbaj",
            "Wajir East",
            "Wajir North",
            "Wajir South",
            "Wajir West"
          ]
        },
        {
          "name": "West Pokot",
          "capital": "Kapenguria",
          "code": 24,
          "sub_counties": [
            "Central Pokot",
            "North Pokot",
            "Pokot South",
            "West Pokot"
          ]
        }
      ]
    });
    const isLoading = ref(false);
    const zones = ref([]);
    const counties = ref([]);
    const constituencies = ref([]);
    const wards = ref([]);
    //const zone = ref('');
    const shops = ref([]);
    const curr_location = ref('');
    const resolved = computed(() => store.state.toasts.length);
    watch(resolved, val => {
      if (val) isLoading.value = false;
    });
    function resetErrors() {
      formData.errors.firstname= false;
      formData.errors.lastname= false;
      formData.errors.phone= false;
      formData.errors.zone= false;
      formData.errors.landmark= false;
      formData.errors.outletname= false;
      formData.errors.constituency= false;
      formData.errors.ward= false;
      formData.errors.county= false;
      formData.errors.shopname= false;
      formData.errors.estate= false;
      formData.errors.street= false;
    }

    function validateForm() {
      resetErrors();
      const errors = [];

      // are passwords equal
      if (
          formData.pw.trim() !== formData.rpw.trim() ||
          formData.pw.trim().length < 5
      ) {
        errors.push("pw");
      }
      // is email valid
      const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
      if (!emailRegex.test(formData.email)) {
        errors.push("email");
      }
      // is username more than 4 letters
      if (formData.firstname.trim().length <= 2) {
        errors.push("firstname");
      }

      if (formData.landmark.trim().length <= 2) {
        errors.push("landmark");
      }

      if (errors.length) {
        errors.forEach(field => {
          const index = field as keyof typeof formData.errors;
          formData.errors[index] = true;
        });

        return false;
      }

      return true;
    }

    function handleSubmit() {
      isLoading.value = true;
      // const isValid = validateForm();
      // if (!isValid) return;

      const form = new FormData();
      form.append('firstname', formData.firstname);
      form.append('lastname', formData.lastname);
      form.append('email', formData.email);
      form.append('password', formData.pw);
      form.append('zone', formData.zone);
      form.append('phone', formData.phone);
      form.append('constituency', formData.constituency);
      form.append('ward', formData.ward);
      form.append('landmark', formData.landmark);
      form.append('shopname', formData.shopname);
      form.append('outletname', formData.outletname);
      form.append('county', formData.county);
      form.append('zip', curr_location.value);
      form.append('estate', formData.estate);
      form.append('street', formData.street);
      const user = JSON.parse(localStorage.getItem("soko-token") || "{}")
      form.append('agent', user[0].id);
      // store.dispatch('register', form);

          axios.post("/api_create", form) .then(function (response) {
            console.log(response.data)
            if (response.data.result == 'ok')
            {
              store.commit("addToast", {
                message: "Registered successfully"
              });
              router.push('/');
              location.reload();
            }else
            {
              store.commit("addToast", {
                type: "error",
                message: "There was an error, try again"
              });
              router.push('/');
            }

          }).catch(function (error) {
                store.commit("addToast", {
                  type: "error",
                  message: error.name + ":" +  error.response.data
                });
                isLoading.value = false;
          });

    }
    function loadZones() {
      axios.get('/zones') .then(function (response) {
        zones.value = response.data;
      }).catch(function (error) {
       //
      });
    }
    function loadCounties() {
      axios.get('/counties') .then(function (response) {
        counties.value = response.data;
      }).catch(function (error) {
        //
      });
    }

    onMounted(() => {
      loadZones();
      loadCounties();
    })


    function loadShops() {
      const form = new FormData();
      form.append('zone',formData.zone);
      axios.post('/stores',form) .then(function (response) {
        shops.value = response.data;
        console.log(response.data)
      }).catch(function (error) {
        //
      });

    }

    function loadConstituencies() {
      const form = new FormData();
      form.append('county_id',formData.county);
      axios.post('/constituencies',form) .then(function (response) {
        constituencies.value = response.data;
        console.log(response.data)
      }).catch(function (error) {
        //
      });

    }

    function loadWards() {
      const form = new FormData();
      form.append('constituency_code',formData.constituency);
      axios.post('/wards',form) .then(function (response) {
        wards.value = response.data;
        console.log(response.data)
      }).catch(function (error) {
        //
      });

    }

    const success = (position: { coords: { latitude: any; longitude: any } }) => {
      const latitude  = position.coords.latitude;
      const longitude = position.coords.longitude;

      curr_location.value = latitude+","+longitude;
    };

    const error = () => {
      alert('Location not enabled');
      router.push('/');
    };

    onMounted(() => {
      // This will open permission popup
      navigator.geolocation.getCurrentPosition(success, error);
    })

    return { ...toRefs(formData), handleSubmit, isLoading,zones,shops,loadShops,loadConstituencies,loadWards,constituencies,counties,wards };
  }
});
</script>

<style lang="postcss" scoped>
.form {
  @apply flex flex-col gap-4;
}
</style>
