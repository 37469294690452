
import { defineComponent, ref} from "vue";
import { useStore } from "vuex";
import axios from "axios";
import router from "@/router";

export default defineComponent({
  setup() {
    const store = useStore();
    const formData = ref("");
    function handleSubmit() {
      const form = new FormData();
      form.append('nid', formData.value);

      axios.post("https://sokojumla.mzawadi.com/agents", form) .then(function (response) {
        console.log(response.data)
        if (response.data.result == 'ok')
        {
          store.commit("setJWTToken", response.data.data);
          store.commit("addToast", {
            message: "Registered successfully"
          });
          router.push('/register');
          // location.reload()
        }else
        {
          store.commit("addToast", {
            type: "error",
            message: "There was an error, try again"
          });
          router.push('/');
        }

      }).catch(function (error) {
        store.commit("addToast", {
          type: "error",
          message: error.name + ":" +  error.response.data
        });
        // isLoading.value = false;
      });
      // router.push('/');
    }
    return {handleSubmit,formData };
  }
});
