import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { store } from "@/store";
import Register from "../views/Register.vue";
import onboarding from "../views/onboarding.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/register",
    name: "Register",
    meta: {
      requiresAuth: true
    },
    component: Register
  },
  {
    path: "/",
    name: "onboarding",
    component: onboarding
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const reqAuth = to.matched.some(record => record.meta.requiresAuth);

  if (!reqAuth) return next();
  // console.log("user",JSON.parse(localStorage.getItem("soko-token")|| '{}'));

  if (!localStorage.getItem("soko-token")) {

    store.commit("addToast", {
      type: "error",
      message: "Auth error: Please provide a correct and registered National ID"
    });

    // console.log(await store.getters.getMe)
    // store.commit('setModal', 'login')

    next({ name: 'onboarding' })
     // return
     // return next(false);
    // next();
  }

  next();

  // if (reqAdmin) {
  //   try {
  //     const userData = await store.getters.getMe;
  //
  //     const roleId = await userData.role.id;
  //     if (roleId !== 1) throw new Error("User is not admin");
  //     return next();
  //   } catch (error) {
  //     store.commit("addToast", {
  //       type: "error",
  //       message: "Auth error: " + error.message
  //     });
  //     next(false);
  //   }
  // }
  // if (reqAuth) {
  //   try {
  //     const userData = await store.getters.getMe;
  //
  //     const isConfirmed = userData.confirmed;
  //     const isBlocked = await userData.blocked;
  //     if (!userData) throw new Error("User not logged in");
  //     if (!isConfirmed) throw new Error("User not confirmed");
  //     if (isBlocked) throw new Error("User is banned");
  //     next();
  //   } catch (error) {
  //     store.commit("addToast", {
  //       type: "error",
  //       message: "Auth error: " + error.message
  //     });
  //     store.commit('setModal', 'login')
  //     // console.log('login')
  //     // next({ name: 'Login' })
  //     // return
  //     next();
  //   }
  // }
});


export default router;
