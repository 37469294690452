<template>
  <form class="form" @submit.prevent="handleSubmit">
    <h1 class="text-4xl font-medium mb-4">Register</h1>
    <base-input
      fullWidth
      v-model="firstname"
      :error="errors.firstname"
      label="FirstName"
      id="register-firstname"
    />
    <base-input
        fullWidth
        v-model="lastname"
        :error="errors.lastname"
        label="LastName"
        id="register-lastname"
    />
    <base-input
      fullWidth
      v-model="email"
      :error="errors.email"
      label="E-mail"
      type="email"
      id="register-email"
    />
    <base-input
        fullWidth
        v-model="phone"
        :error="errors.phone"
        label="Phone"
        id="register-phone"
    />
    <base-input
        fullWidth
        v-model="zone"
        :error="errors.zone"
        label="Zone"
        id="register-zone"
    />

    <base-input
      fullWidth
      v-model="password"
      :error="errors.password"
      label="Password"
      type="password"
      id="register-password"
    />
    <base-input
      fullWidth
      v-model="rpw"
      :error="errors.password"
      label="Repeat password"
      type="password"
      id="register-rpw"
    />
    <label :class="{ 'text-red-500': errors.agreement }">
      <input
        id="register-agreement"
        v-model="agreement"
        class="mr-4"
        type="checkbox"
      />
      I agree to terms and conditions.</label
    >
    <base-button :disabled="isLoading" fullWidth>
      <spinner class="spinner-sm" v-if="isLoading" />
      <span v-else>
        Register
      </span>
    </base-button>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed, watch, toRefs } from "vue";
import { useStore } from "vuex";
import axios from "axios";

export default defineComponent({
  setup() {
    const store = useStore();

    const formData = reactive({
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      zone: "",
      password: "",
      rpw: "",
      agreement: false,
      errors: {
        firstname: false,
        lastname: false,
        email: false,
        phone: false,
        zone: false,
        password: false,
      }
    });
    const isLoading = ref(false);
    const resolved = computed(() => store.state.toasts.length);
    watch(resolved, val => {
      if (val) isLoading.value = false;
    });
    function resetErrors() {
      formData.errors.firstname = false;
      formData.errors.lastname = false;
      formData.errors.email = false;
      formData.errors.phone = false;
      formData.errors.zone = false;
      formData.errors.password = false;
    }

    function validateForm() {
      resetErrors();
      const errors = [];

      // has user agreed?
      if (!formData.agreement) {
        errors.push("agreement");
      }
      // are passwords equal
      if (
        formData.password.trim() !== formData.rpw.trim() ||
        formData.password.trim().length < 5
      ) {
        errors.push("password");
      }
      // is email valid
      const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
      if (!emailRegex.test(formData.email)) {
        errors.push("email");
      }
      // is firstname more than 2 letters
      if (formData.firstname.trim().length <= 2) {
        errors.push("firstname");
      }

      // is lastname more than 2 letters
      if (formData.lastname.trim().length <= 2) {
        errors.push("lastname");
      }

      // is lastname more than 2 letters
      if (formData.zone.trim().length <= 2) {
        errors.push("zone");
      }

      if (errors.length) {
        errors.forEach(field => {
          const index = field as keyof typeof formData.errors;
          formData.errors[index] = true;
        });

        return false;
      }

      return true;
    }

    function handleSubmit() {
      // isLoading.value = true;
      // const isValid = validateForm();
      // if (!isValid) return;
      //
      // let form = new FormData();
      // form.append('firstname', formData.firstname);
      // form.append('lastname', formData.lastname);
      // form.append('email', formData.email);
      // form.append('password', formData.password);
      // form.append('zone', formData.zone);
      // form.append('phone', formData.phone);
      // form.append('shopname', 'JoysCa');
      // form.append('zip', '36.2422423,-1.273421423');
      // form.append('estate', 'Utawala');
      // form.append('street', 'Total Langata');
      // store.dispatch('register', form);
      // // store.dispatch('register', {
      // //   username: formData.username,
      // //   password: formData.pw,
      // //   email: formData.email
      // // });
    }
    return { ...toRefs(formData), handleSubmit, isLoading };
  }
});
</script>

<style lang="postcss" scoped>
.form {
  @apply flex flex-col gap-4;
}
</style>
