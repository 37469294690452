
import { defineComponent, computed, watch, ref } from "vue";
import { useStore } from 'vuex';
// import UserPopup from '@/components/popups/UserPopup.vue';

export default defineComponent({
  // components: { UserPopup },
  name: 'Navigation',
  methods: {
    triggerMenu() {
      this.isNavOpen = !this.isNavOpen;
    }
  },

  data() {
    return {
      isNavOpen: false,
      isProfileOpen: false,
      isUserAdmin: false
    };
  },
  setup(props) {
    const store = useStore();
    return {};
  },
  props: {
    routes: Array
  }
});
