
import navigation from '@/components/organisms/Navigation.vue';
// import BaseFooter from '@/components/organisms/BaseFooter.vue';
import RegisterForm from '@/components/popups/RegisterForm.vue';
import LoginForm from '@/components/popups/LoginForm.vue';
import ConfirmPayment from "@/components/popups/ConfirmPayment.vue";
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    navigation,
    RegisterForm,
    LoginForm
  },
  data() {
    return {
      routes: [
        { path: "/", name: "Home" },
        { path: "/products", name: "Products" }
      ]
    };
  },

  methods: {},

  created() {
    this.$store.dispatch("onInit");
  }
});
