
import { defineComponent, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  data() {
    return {
      login: '',
      password: ''
    };
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const resolved = computed(() => store.state.toasts.length);
    watch(resolved, val => {
      if (val) isLoading.value = false;
    });
    return {
      isLoading
    };
  },
  methods: {
    handleSubmit() {
      const form = new FormData();
      form.append("email",this.login.trim());
      form.append("password",this.password.trim());
      this.$store.dispatch("authorize", form);
      this.isLoading = true;
    }
  }
});
