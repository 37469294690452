
import { onMounted,defineComponent, ref, reactive, computed, watch, toRefs } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import router from "@/router";

export default defineComponent({

  setup() {
    const store = useStore();

    const formData = reactive({
      firstname: "",
      lastname: "",
      phone: "",
      zone: "",
      shopname: "",
      constituency:"",
      landmark:"",
      outletname:"",
      county:"",
      estate: "",
      street: "",
      ward: "",
      zip: "",
      email: "",
      pw: "",
      rpw: "",
      errors: {
        firstname: false,
        lastname: false,
        phone: false,
        landmark:false,
        zone: false,
        shopname: false,
        outletname: false,
        county: false,
        estate: false,
        street: false,
        ward: false,
        constituency: false,
        zip: false,
        email: false,
        pw: false,
      },
      counties_:[
        {
          "name": "Baringo",
          "capital": "Kabarnet",
          "code": 30,
          "sub_counties": [
            "Baringo central",
            "Baringo north",
            "Baringo south",
            "Eldama ravine",
            "Mogotio",
            "Tiaty"
          ]
        },
        {
          "name": "Bomet",
          "capital": "Bomet",
          "code": 36,
          "sub_counties": [
            "Bomet central",
            "Bomet east",
            "Chepalungu",
            "Konoin",
            "Sotik"
          ]
        },
        {
          "name": "Bungoma",
          "capital": "Bungoma",
          "code": 39,
          "sub_counties": [
            "Bumula",
            "Kabuchai",
            "Kanduyi",
            "Kimilil",
            "Mt Elgon",
            "Sirisia",
            "Tongaren",
            "Webuye east",
            "Webuye west"
          ]
        },
        {
          "name": "Busia",
          "capital": "Busia",
          "code": 40,
          "sub_counties": [
            "Budalangi",
            "Butula",
            "Funyula",
            "Nambele",
            "Teso North",
            "Teso South"
          ]
        },
        {
          "name": "Elgeyo-Marakwet",
          "capital": "Iten",
          "code": 28,
          "sub_counties": [
            "Keiyo north",
            "Keiyo south",
            "Marakwet east",
            "Marakwet west"
          ]
        },
        {
          "name": "Embu",
          "capital": "Embu",
          "code": 14,
          "sub_counties": [
            "Manyatta",
            "Mbeere north",
            "Mbeere south",
            "Runyenjes"
          ]
        },
        {
          "name": "Garissa",
          "capital": "Garissa",
          "code": 7,
          "sub_counties": [
            "Daadab",
            "Fafi",
            "Garissa",
            "Hulugho",
            "Ijara",
            "Lagdera balambala"
          ]
        },
        {
          "name": "Homa Bay",
          "capital": "Homa Bay",
          "code": 43,
          "sub_counties": [
            "Homabay town",
            "Kabondo",
            "Karachwonyo",
            "Kasipul",
            "Mbita",
            "Ndhiwa",
            "Rangwe",
            "Suba"
          ]
        },
        {
          "name": "Isiolo",
          "capital": "Isiolo",
          "code": 11,
          "sub_counties": [
            "Central",
            "Garba tula",
            "Kina",
            "Merit",
            "Oldonyiro",
            "Sericho"
          ]
        },
        {
          "name": "Kajiado",
          "code": 34,
          "sub_counties": [
            "Isinya.",
            "Kajiado Central.",
            "Kajiado North.",
            "Loitokitok.",
            "Mashuuru."
          ]
        },
        {
          "name": "Kakamega",
          "capital": "Kakamega",
          "code": 37,
          "sub_counties": [
            "Butere",
            "Kakamega central",
            "Kakamega east",
            "Kakamega north",
            "Kakamega south",
            "Khwisero",
            "Lugari",
            "Lukuyani",
            "Lurambi",
            "Matete",
            "Mumias",
            "Mutungu",
            "Navakholo"
          ]
        },
        {
          "name": "Kericho",
          "capital": "Kericho",
          "code": 35,
          "sub_counties": [
            "Ainamoi",
            "Belgut",
            "Bureti",
            "Kipkelion east",
            "Kipkelion west",
            "Soin sigowet"
          ]
        },
        {
          "name": "Kiambu",
          "capital": "Kiambu",
          "code": 22,
          "sub_counties": [
            "Gatundu north",
            "Gatundu south",
            "Githunguri",
            "Juja",
            "Kabete",
            "Kiambaa",
            "Kiambu",
            "Kikuyu",
            "Limuru",
            "Ruiru",
            "Thika town",
            "lari"
          ]
        },
        {
          "name": "Kilifi",
          "capital": "Kilifi",
          "code": 3,
          "sub_counties": [
            "Genzw",
            "Kaloleni",
            "Kilifi north",
            "Kilifi south",
            "Magarini",
            "Malindi",
            "Rabai"
          ]
        },
        {
          "name": "Kirinyaga",
          "capital": "Kerugoya/Kutus",
          "code": 20,
          "sub_counties": [
            "Kirinyaga central",
            "Kirinyaga east",
            "Kirinyaga west",
            "Mwea east",
            "Mwea west"
          ]
        },
        {
          "name": "Kisii",
          "capital": "Kisii",
          "code": 45,
          "sub_counties": [

          ]
        },
        {
          "name": "Kisumu",
          "capital": "Kisumu",
          "code": 42,
          "sub_counties": [
            "Kisumu central",
            "Kisumu east ",
            "Kisumu west",
            "Mohoroni",
            "Nyakach",
            "Nyando",
            "Seme"
          ]
        },
        {
          "name": "Kitui",
          "capital": "Kitui",
          "code": 15,
          "sub_counties": [
            "Ikutha",
            "Katulani",
            "Kisasi",
            "Kitui central",
            "Kitui west ",
            "Lower yatta",
            "Matiyani",
            "Migwani",
            "Mutitu",
            "Mutomo",
            "Muumonikyusu",
            "Mwingi central",
            "Mwingi east",
            "Nzambani",
            "Tseikuru"
          ]
        },
        {
          "name": "Kwale",
          "capital": "Kwale",
          "code": 2,
          "sub_counties": [
            "Kinango",
            "Lungalunga",
            "Msambweni",
            "Mutuga"
          ]
        },
        {
          "name": "Laikipia",
          "capital": "Rumuruti",
          "code": 31,
          "sub_counties": [
            "Laikipia central",
            "Laikipia east",
            "Laikipia north",
            "Laikipia west ",
            "Nyahururu"
          ]
        },
        {
          "name": "Lamu",
          "capital": "Lamu",
          "code": 5,
          "sub_counties": [
            "Lamu East",
            "Lamu West"
          ]
        },
        {
          "name": "Machakos",
          "capital": "Machakos",
          "code": 16,
          "sub_counties": [
            "Kathiani",
            "Machakos town",
            "Masinga",
            "Matungulu",
            "Mavoko",
            "Mwala",
            "Yatta"
          ]
        },
        {
          "name": "Makueni",
          "capital": "Wote",
          "code": 17,
          "sub_counties": [
            "Kaiti",
            "Kibwei west",
            "Kibwezi east",
            "Kilome",
            "Makueni",
            "Mbooni"
          ]
        },
        {
          "name": "Mandera",
          "capital": "Mandera",
          "code": 9,
          "sub_counties": [
            "Banissa",
            "Lafey",
            "Mandera East",
            "Mandera North",
            "Mandera South",
            "Mandera West"
          ]
        },
        {
          "name": "Marsabit",
          "capital": "Marsabit",
          "code": 10,
          "sub_counties": [
            "Laisamis",
            "Moyale",
            "North hor",
            "Saku"
          ]
        },
        {
          "name": "Meru",
          "capital": "Meru",
          "code": 12,
          "sub_counties": [
            "Buuri",
            "Igembe central",
            "Igembe north",
            "Igembe south",
            "Imenti central",
            "Imenti north",
            "Imenti south",
            "Tigania east",
            "Tigania west"
          ]
        },
        {
          "name": "Migori",
          "capital": "Migori",
          "code": 44,
          "sub_counties": [
            "Awendo",
            "Kuria east",
            "Kuria west",
            "Mabera",
            "Ntimaru",
            "Rongo",
            "Suna east",
            "Suna west",
            "Uriri"
          ]
        },
        {
          "name": "Mombasa",
          "capital": "Mombasa City",
          "code": 1,
          "sub_counties": [
            "Changamwe",
            "Jomvu",
            "Kisauni",
            "Likoni",
            "Mvita",
            "Nyali"
          ]
        },
        {
          "name": "Murang'a",
          "capital": "Murang'a",
          "code": 21,
          "sub_counties": [
            "Gatanga",
            "Kahuro",
            "Kandara",
            "Kangema",
            "Kigumo",
            "Kiharu",
            "Mathioya",
            "Murang’a south"
          ]
        },
        {
          "name": "Nairobi",
          "capital": "Nairobi City",
          "code": 47,
          "sub_counties": [
            "Dagoretti North Sub County",
            "Dagoretti South Sub County ",
            "Embakasi Central Sub Count",
            "Embakasi East Sub County",
            "Embakasi North Sub County ",
            "Embakasi South Sub County",
            "Embakasi West Sub County",
            "Kamukunji Sub County",
            "Kasarani Sub County ",
            "Kibra Sub County ",
            "Lang'ata Sub County ",
            "Makadara Sub County",
            "Mathare Sub County ",
            "Roysambu Sub County ",
            "Ruaraka Sub County ",
            "Starehe Sub County ",
            "Westlands Sub County "
          ]
        },
        {
          "name": "Nakuru",
          "capital": "Nakuru",
          "code": 32,
          "sub_counties": [
            "Bahati",
            "Gilgil",
            "Kuresoi north",
            "Kuresoi south",
            "Molo",
            "Naivasha",
            "Nakuru town east",
            "Nakuru town west",
            "Njoro",
            "Rongai",
            "Subukia"
          ]
        },
        {
          "name": "Nandi",
          "capital": "Kapsabet",
          "code": 29,
          "sub_counties": [
            "Aldai",
            "Chesumei",
            "Emgwen",
            "Mosop",
            "Namdi hills",
            "Tindiret"
          ]
        },
        {
          "name": "Narok",
          "capital": "Narok",
          "code": 33,
          "sub_counties": [
            "Narok east",
            "Narok north",
            "Narok south",
            "Narok west",
            "Transmara east",
            "Transmara west"
          ]
        },
        {
          "name": "Nyamira",
          "capital": "Nyamira",
          "code": 46,
          "sub_counties": [
            "Borabu",
            "Manga",
            "Masaba north",
            "Nyamira north",
            "Nyamira south"
          ]
        },
        {
          "name": "Nyandarua",
          "capital": "Ol Kalou",
          "code": 18,
          "sub_counties": [
            "Kinangop",
            "Kipipiri",
            "Ndaragwa",
            "Ol Kalou",
            "Ol joro orok"
          ]
        },
        {
          "name": "Nyeri",
          "capital": "Nyeri",
          "code": 19,
          "sub_counties": [
            "Kieni east",
            "Kieni west",
            "Mathira east",
            "Mathira west",
            "Mkurweni",
            "Nyeri town",
            "Othaya",
            "Tetu"
          ]
        },
        {
          "name": "Samburu",
          "capital": "Maralal",
          "code": 25,
          "sub_counties": [
            "Samburu east",
            "Samburu north",
            "Samburu west"
          ]
        },
        {
          "name": "Siaya",
          "capital": "Siaya",
          "code": 41,
          "sub_counties": [
            "Alego usonga",
            "Bondo",
            "Gem",
            "Rarieda",
            "Ugenya",
            "Unguja"
          ]
        },
        {
          "name": "Taita-Taveta",
          "capital": "Voi",
          "code": 6,
          "sub_counties": [
            "Mwatate",
            "Taveta",
            "Voi",
            "Wundanyi"
          ]
        },
        {
          "name": "Tana River",
          "capital": "Hola",
          "code": 4,
          "sub_counties": [
            "Bura",
            "Galole",
            "Garsen"
          ]
        },
        {
          "name": "Tharaka-Nithi",
          "capital": "Chuka",
          "code": 13,
          "sub_counties": [
            "Chuka",
            "Igambangobe",
            "Maara",
            "Muthambi",
            "Tharak north",
            "Tharaka south"
          ]
        },
        {
          "name": "Trans-Nzoia",
          "capital": "Kitale",
          "code": 26,
          "sub_counties": [
            "Cherangany",
            "Endebess",
            "Kiminini",
            "Kwanza",
            "Saboti"
          ]
        },
        {
          "name": "Turkana",
          "capital": "Lodwar",
          "code": 23,
          "sub_counties": [
            "Loima",
            "Turkana central",
            "Turkana east",
            "Turkana north",
            "Turkana south"
          ]
        },
        {
          "name": "Uasin Gishu",
          "capital": "Eldoret",
          "code": 27,
          "sub_counties": [
            "Ainabkoi",
            "Kapseret",
            "Kesses",
            "Moiben",
            "Soy",
            "Turbo"
          ]
        },
        {
          "name": "Vihiga",
          "capital": "Vihiga",
          "code": 38,
          "sub_counties": [
            "Emuhaya",
            "Hamisi",
            "Luanda",
            "Sabatia",
            "vihiga"
          ]
        },
        {
          "name": "Wajir",
          "capital": "Wajir",
          "code": 8,
          "sub_counties": [
            "Eldas",
            "Tarbaj",
            "Wajir East",
            "Wajir North",
            "Wajir South",
            "Wajir West"
          ]
        },
        {
          "name": "West Pokot",
          "capital": "Kapenguria",
          "code": 24,
          "sub_counties": [
            "Central Pokot",
            "North Pokot",
            "Pokot South",
            "West Pokot"
          ]
        }
      ]
    });
    const isLoading = ref(false);
    const zones = ref([]);
    const counties = ref([]);
    const constituencies = ref([]);
    const wards = ref([]);
    //const zone = ref('');
    const shops = ref([]);
    const curr_location = ref('');
    const resolved = computed(() => store.state.toasts.length);
    watch(resolved, val => {
      if (val) isLoading.value = false;
    });
    function resetErrors() {
      formData.errors.firstname= false;
      formData.errors.lastname= false;
      formData.errors.phone= false;
      formData.errors.zone= false;
      formData.errors.landmark= false;
      formData.errors.outletname= false;
      formData.errors.constituency= false;
      formData.errors.ward= false;
      formData.errors.county= false;
      formData.errors.shopname= false;
      formData.errors.estate= false;
      formData.errors.street= false;
    }

    function validateForm() {
      resetErrors();
      const errors = [];

      // are passwords equal
      if (
          formData.pw.trim() !== formData.rpw.trim() ||
          formData.pw.trim().length < 5
      ) {
        errors.push("pw");
      }
      // is email valid
      const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
      if (!emailRegex.test(formData.email)) {
        errors.push("email");
      }
      // is username more than 4 letters
      if (formData.firstname.trim().length <= 2) {
        errors.push("firstname");
      }

      if (formData.landmark.trim().length <= 2) {
        errors.push("landmark");
      }

      if (errors.length) {
        errors.forEach(field => {
          const index = field as keyof typeof formData.errors;
          formData.errors[index] = true;
        });

        return false;
      }

      return true;
    }

    function handleSubmit() {
      isLoading.value = true;
      // const isValid = validateForm();
      // if (!isValid) return;

      const form = new FormData();
      form.append('firstname', formData.firstname);
      form.append('lastname', formData.lastname);
      form.append('email', formData.email);
      form.append('password', formData.pw);
      form.append('zone', formData.zone);
      form.append('phone', formData.phone);
      form.append('constituency', formData.constituency);
      form.append('ward', formData.ward);
      form.append('landmark', formData.landmark);
      form.append('shopname', formData.shopname);
      form.append('outletname', formData.outletname);
      form.append('county', formData.county);
      form.append('zip', curr_location.value);
      form.append('estate', formData.estate);
      form.append('street', formData.street);
      const user = JSON.parse(localStorage.getItem("soko-token") || "{}")
      form.append('agent', user[0].id);
      // store.dispatch('register', form);

          axios.post("/api_create", form) .then(function (response) {
            console.log(response.data)
            if (response.data.result == 'ok')
            {
              store.commit("addToast", {
                message: "Registered successfully"
              });
              router.push('/');
              location.reload();
            }else
            {
              store.commit("addToast", {
                type: "error",
                message: "There was an error, try again"
              });
              router.push('/');
            }

          }).catch(function (error) {
                store.commit("addToast", {
                  type: "error",
                  message: error.name + ":" +  error.response.data
                });
                isLoading.value = false;
          });

    }
    function loadZones() {
      axios.get('/zones') .then(function (response) {
        zones.value = response.data;
      }).catch(function (error) {
       //
      });
    }
    function loadCounties() {
      axios.get('/counties') .then(function (response) {
        counties.value = response.data;
      }).catch(function (error) {
        //
      });
    }

    onMounted(() => {
      loadZones();
      loadCounties();
    })


    function loadShops() {
      const form = new FormData();
      form.append('zone',formData.zone);
      axios.post('/stores',form) .then(function (response) {
        shops.value = response.data;
        console.log(response.data)
      }).catch(function (error) {
        //
      });

    }

    function loadConstituencies() {
      const form = new FormData();
      form.append('county_id',formData.county);
      axios.post('/constituencies',form) .then(function (response) {
        constituencies.value = response.data;
        console.log(response.data)
      }).catch(function (error) {
        //
      });

    }

    function loadWards() {
      const form = new FormData();
      form.append('constituency_code',formData.constituency);
      axios.post('/wards',form) .then(function (response) {
        wards.value = response.data;
        console.log(response.data)
      }).catch(function (error) {
        //
      });

    }

    const success = (position: { coords: { latitude: any; longitude: any } }) => {
      const latitude  = position.coords.latitude;
      const longitude = position.coords.longitude;

      curr_location.value = latitude+","+longitude;
    };

    const error = () => {
      alert('Location not enabled');
      router.push('/');
    };

    onMounted(() => {
      // This will open permission popup
      navigator.geolocation.getCurrentPosition(success, error);
    })

    return { ...toRefs(formData), handleSubmit, isLoading,zones,shops,loadShops,loadConstituencies,loadWards,constituencies,counties,wards };
  }
});
